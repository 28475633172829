import { Anchor, Title, Button, Group, Space, Loader } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';

import homePageLoop from './Media/AKFC-Home-Page-Loop.mp4'
import GetFromCloud from './Services/GetFromCloud';

function Splash(props) {

    const wpwOrange = "#f37121"
    const wpwJoinEtobicokeUnitedLink = "https://secure3.convio.net/akfc/site/TR/2022WPW/General/590963584?pg=team&fr_id=1717&team_id=21981"
    const donationLink = "https://secure3.convio.net/akfc/site/Donation2?idb=612138846&df_id=1873&FR_ID=1717&mfc_pref=T&PROXY_ID=1227943&PROXY_TYPE=20&1873.donation=form1"

    const isMounted = useRef()

    const getAmountRaised = async () => {
        GetFromCloud.getAmountRaisedFromGoogleSheets().then((response) => {
            const amount = response.amountRaised
            
            if(amount !== -1) {
                setAmountRaised(amount)
            }
            props.ga.event({
                category: 'App Metrics',
                action: 'Amount Raised Loaded',
                label: 'Amount Raised',
                value: amount
              })
        })
        
    }
    const [amountRaised, setAmountRaised] = useState(<Loader color={"white"} style={{marginLeft: "10px"}} size={16} ></Loader>)

    useEffect(() => {

        if(isMounted.current) {
            return
        }

        isMounted.current = true

        getAmountRaised()
    },[])
  
  return (
    <div>
    
        <video autoPlay loop muted
            style={{opacity: 1, objectFit: "cover", backgroundSize: "cover", width: "100%", height: "100%", position: "absolute", top: "0px", left: "0px", display: "block"}}>
              <source src={homePageLoop} type='video/mp4' />
        </video>
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: "translate(-50%, -50%)",
                width: 'fit-content',
                padding: "30px",
                color: 'white'
            }}>
            <Title order={2}>Join Etobicoke United</Title>
            <Title order={4}>in support of the</Title>
            <Title order={1}>World Partnership Walk</Title>
            <Space h="md"></Space>
            <Title order={3}>Amount Raised: {amountRaised}</Title>
            <Space h="xl"></Space>
            <Group direction="column" position="center">
                {/*<Button style={{backgroundColor: wpwOrange}}>
                    <Anchor
                        onClick={() => props.ga.event({
                            category: 'Behaviour',
                            action: 'Button Click',
                            label: 'Home Page - Join the Team'
                        })}
                        style={{color: "white"}} target={"_blank"} href={wpwJoinEtobicokeUnitedLink}>Join the Etobicoke United Team</Anchor>
                </Button>*/}
                <Button style={{backgroundColor: wpwOrange}}
                    onClick={() => {
                        props.ga.event({
                            category: 'Behaviour',
                            action: 'Button Click',
                            label: 'Home Page - Walk/BBQ Info & Tickets'
                        })
                        props.goToMain(true)}}
                >Walk/BBQ Info & Tickets</Button>
                <Button style={{backgroundColor: wpwOrange}}>
                    <Anchor size="sm"
                        onClick={() => props.ga.event({
                            category: 'Behaviour',
                            action: 'Button Click',
                            label: 'Home Page - Donate'
                        })}
                        style={{color: "white"}} target={"_blank"} href={donationLink}>Donate</Anchor>
                </Button>
            </Group>
        </div>
            
        
        
      
    </div>
  );
}

export default Splash;
