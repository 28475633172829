let GetFromCloud = {}

GetFromCloud.getAmountRaisedFromGoogleSheets = async function getAmountRaisedFromGoogleSheets() {
    var requestOptions = {
  method: 'GET',
  redirect: 'follow'
};

return fetch("https://script.google.com/macros/s/AKfycbzcDGof8Q6yRunPNbmqynptE8egXc-VMSY3p8UfSlUJgRnb3jBYqeJBNByvw5sBLflJ/exec", requestOptions)
  .then(response => response.json())
  .then(result => {return result})
  .catch(error => {return error});
}

export default GetFromCloud