import {Text, Modal, Group, Loader, Space, Button } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { Checkbox } from "tabler-icons-react";
import SendToCloud from "./Services/SendToCloud";

function SubmittingTicketRequest(props) {

    const [opened, setOpened] = useState(props.opened);
    const [submitStage, setSubmitStage] = useState(true)

    const [showModalCloseButton, setShowModalCloseButton] = useState(false)
    const isMounted = useRef()
    const wpwOrange = "#f37121"
    const donationLink = "https://secure3.convio.net/akfc/site/Donation2?idb=612138846&df_id=1873&FR_ID=1717&mfc_pref=T&PROXY_ID=1227943&PROXY_TYPE=20&1873.donation=form1"

    const submitTicketRequest = () => {

        const ticketRequest = props.ticketRequest
        //console.log(opened)

        props.ga.event({
            category: 'User Tracking',
            action: 'Reg Data Send Start',
            label: ticketRequest["Last Name"],
            value: ticketRequest["Ticket Quantity"]
        })

        SendToCloud.postTicketRequestToGoogleSheets(ticketRequest).then((response) => {
            if(response === "") {
                setSubmitStage(false)
                props.ga.event({
                    category: 'User Tracking',
                    action: 'Reg Data Send Success',
                    label: ticketRequest["Last Name"],
                    value: ticketRequest["Ticket Quantity"]
                })
            }
        })
    }

    const donateNow = () => {

        window.open(donationLink)

        setShowModalCloseButton(true)
        /*setTimeout(() => {
            window.open(donationLink)
            setShowModalCloseButton(true)
        }, 5000)*/
        
        

    }

    useEffect(() => {

        if(isMounted.current) {

            return
        }

        isMounted.current = true
            
        submitTicketRequest()

    }
    ,[])

    return (
        <Modal
            withCloseButton={showModalCloseButton}
            opened={opened}
            onClose={() => {
                //setFirstLoad(true)
                props.setTicketModalOpened(false)
                props.setOpened(false)
            }}
        >
            
                <Space h="md"></Space>
                
                    <Group direction="column" position="center">
                    {submitStage
                    ?
                        <Group direction="column" position="center">
                            <Loader variant="dots" />
                            <Text size="sm" weight={"bold"}>Submitting your registration information</Text>
                        </Group>
                        
                    :
            
                        <div>
                        <Group direction="row">
                            <Text weight={"bold"} size={"sm"}>Registration information submitted</Text>
                            <Checkbox
                                size={20}
                                strokeWidth={3}
                                color={'#40bf43'}
                            />
                        </Group>
                        </div>
                        
                    }
                    </Group>
                    
                
                <Space h="md"></Space>
                
                {!submitStage?
                    <Group direction="column" position="center">
                        <Text align="center">Click 'Donate Now' to pay for your tickets.</Text>
                        <Text align="center">Your minimum donation amount is ${props.minimumDonation}.00</Text>
                        <Text align="center">Please use the same email address you used in your registration ({props.emailAddress})</Text>
                        <Button
                            onClick={() => {
                                props.ga.event({
                                    category: 'Behaviour',
                                    action: 'Button Click',
                                    label: 'Walk/BBQ - Donate Now (Submitting Ticket Request Modal)'
                                })
                                donateNow()
                                }}
                             style={{backgroundColor: wpwOrange, color: 'white'}}
                        >
                            Donate now
                        </Button>   
                        <Text align="center">Thank you for your support!</Text>
                        <Text align="center">Your physical ticket(s) can be picked up at the registration desk on the day of the event.</Text>
                    </Group>
                :
                null
                }

                
            
      </Modal>

    )
}
export default SubmittingTicketRequest;