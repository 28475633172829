import { Text, Space } from "@mantine/core";

function EventDetails(props) {

    return (
        <div>
            
            <Space h="md"></Space>
            <Text align="left">
                <strong>Date:</strong> Sunday, June 19, 2022 {"(YES --> It's Father's Day!)"}
            </Text>
            <Text align="left">
                <strong>Time:</strong> Walk starts at 11:00 AM
            </Text>
            <Text align="left">
                <strong>Where:</strong> Centennial Park - Picnic Area 3, 130 Elmcrest Rd, Etobicoke, ON M9C 3S2
            </Text>
            <Text align="left">
                <strong>Cost:</strong> $5 minimum per person with all ticket sales going to the World Partnership Walk
            </Text>
            <Text align="left">
                <strong>Space at the picnic area is limited and tickets are now SOLD OUT.  Additional tickets WILL NOT be sold on the day of the event.</strong>
            </Text>
        </div>
    )
}
export default EventDetails;