import Main from './Main';
import './App.css';
import { useState } from 'react';
import Splash from './Splash';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-229523485-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [clickedThrough, setClickedThrough] = useState(false)
  return (
    <div className="App">
    {clickedThrough ? <Main ga={ReactGA}></Main>: <Splash ga={ReactGA} goToMain={setClickedThrough}></Splash>}
      
    </div>
  );
}

export default App;
