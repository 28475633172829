import { Accordion, Button, Center, Group, Container, Space, Title, Text, useAccordionState, Image, Modal } from "@mantine/core";
import { CircleMinus, CirclePlus, Mail, Map, QuestionMark, Ticket, Walk } from 'tabler-icons-react';
import AboutTheWalk from "./AccordionItems/AboutTheWalk";
import ContactUs from "./AccordionItems/ContactUs";
import Directions from "./AccordionItems/Directions";
import EventDetails from "./AccordionItems/EventDetails";
import FAQ from "./AccordionItems/FAQ";
import Tickets from "./AccordionItems/Tickets";
import { useState } from "react";
import { useScrollIntoView } from '@mantine/hooks';
import parkPhoto from './Media/parkPhoto.jpeg'
import TicketModal from "./TicketModal";
import wpwIcon from "./Media/wpwIcon.png"


function Main(props) {

    const { scrollDirectionsIntoView, directionsAccordionItem } = useScrollIntoView();
    const { scrollIntoView, targetRef } = useScrollIntoView();
    const wpwOrange = "#f37121"

    //const directionsAccordionItem = useRef()
    const [state, handlers] = useAccordionState({ total: 6, initialItem: -1 });

    const scrollThem = () => {
        scrollDirectionsIntoView({ alignment: 'start' })
    }
    const [opened, setOpened] = useState(false);
    const closeModal = () => {
        setOpened(false)
    }
    
    return (
        <div>
            <Modal size={"500px"} title="Registration & Ticket Purchase" opened={opened} onClose={closeModal}>
                <TicketModal ga={props.ga} setTicketModalOpened={setOpened}></TicketModal>
            </Modal>
            <Container>
                <Center>
                    <Group direction="column" position="center">
                    <Space h="xl"></Space>
                    <Title align="center" order={2}>Walk and BBQ Information</Title>
                    <Text>
                        Join Etobicoke United for a walk and BBQ in support of the World Partnership Walk!
                    </Text>
                    <Text>
                        Celebrate Father's Day with a walk through Centennial Park, a BBQ lunch, children's games, soccer, and other exciting park/picnic activities!
                    </Text>
                    <Text>
                        All proceeds from ticket sales go to the World Partnership Walk.
                    </Text>
                    <Text size="lg" weight={"bold"} color={"red"}>
                        TICKETS FOR THIS EVENT ARE NOW SOLD OUT. NO TICKETS WILL BE AVAILABLE ON THE DAY OF THE EVENT.
                    </Text>
                    <Button
                        
                        disabled
                        onClick={() => {
                            const val = 2
                            let newState = {}
                            for (var key of Object.keys(state)) {
                                newState = {...newState, [key]: false}
                            }
                            //handlers.setState({...newState, [val]: true})
                            handlers.setState({...state, [val]: true})
                            scrollIntoView({ alignment: 'start' })
                            props.ga.event({
                                category: 'Behaviour',
                                action: 'Button Click',
                                label: 'Walk/BBQ - Buy Tickets (top)'
                            })
                            
                        }}
                    >
                        Register for Tickets - SOLD OUT
                    </Button>
                    </Group>
                </Center>
                <Space h="md"></Space>
                <Accordion state={state} onChange={handlers.setState} iconPosition={"right"} icon={<CirclePlus size={16} />} disableIconRotation>
                    <Accordion.Item icon={state[0] ? <CircleMinus size={16}/> : <CirclePlus size={16}/>} label={<Group direction="row" position="left"><Image src={wpwIcon} width={24}></Image><Text>The World Partnership Walk</Text></Group>}>
                        <AboutTheWalk></AboutTheWalk>
                    </Accordion.Item>
                    <Accordion.Item icon={state[1] ? <CircleMinus size={16}/> : <CirclePlus size={16}/>} label={<Group direction="row" position="left"><Walk size={24} color={wpwOrange} strokeWidth={2}></Walk><Text>Event Details</Text></Group>} >
                        <EventDetails handlers={handlers} scrollThem={scrollThem}></EventDetails>
                    </Accordion.Item>
                    <Accordion.Item icon={state[2] ? <CircleMinus size={16}/> : <CirclePlus size={16}/>} controlRef={targetRef} label={<Group direction="row" position="left"><Ticket size={24} strokeWidth={2} color={wpwOrange}></Ticket><Text>Tickets & Donations</Text></Group>}>
                        <Tickets ga={props.ga} openTicketModal={setOpened}></Tickets>
                    </Accordion.Item>
                    <Accordion.Item icon={state[3] ? <CircleMinus size={16}/> : <CirclePlus size={16}/>} controlRef={directionsAccordionItem} label={<Group direction="row" position="left"><Map size={24} color={wpwOrange} strokeWidth={2}></Map><Text>Directions, Parking & Maps</Text></Group>}>
                        <Directions ></Directions>
                    </Accordion.Item>
                    <Accordion.Item icon={state[4] ? <CircleMinus size={16}/> : <CirclePlus size={16}/>} label={<Group direction="row" position="left"><QuestionMark size={24} color={wpwOrange} strokeWidth={2}></QuestionMark><Text>Frequently Asked Questions</Text></Group>}>
                        <FAQ></FAQ>
                    </Accordion.Item>
                    <Accordion.Item icon={state[5] ? <CircleMinus size={16}/> : <CirclePlus size={16}/>} label={<Group direction="row" position="left"><Mail size={24} color={wpwOrange} strokeWidth={2}></Mail><Text>Contact Us</Text></Group>}>
                        <ContactUs></ContactUs>
                    </Accordion.Item>
                </Accordion>
                <Space h="xl"></Space>
                <Image src={parkPhoto} style={{margin: 'auto', maxWidth: '600px'}}></Image>
                <Space h="xl"></Space>
                <Text>Centennial Park</Text>
                <Space h="xl"></Space>
                {
                //title
                //amount raised
                //shortcut buttons
                    //buy tickets
                //Event Details
                //Tickets and Donations
                //Directions & Parking Info
                //Event FAQ’s
                //Learn about the Walk
                }
            </Container>
            
        </div>
    )
}
export default Main;