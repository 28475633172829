import { Title, Text, Space, Anchor } from "@mantine/core";

function ContactUs(props) {

    return (
        <div>
            <Space h="md"></Space>
            <Title align="left" order={4}>
                Still have questions?
            </Title>
            <Space h="sm"></Space>
            <Text align="left">
                If you can't find the answer to your questions in any of the sections above, please don't hesitate to email us at <Anchor href="mailto:contact@etobicokeunited.ca">contact@etobicokeunited.ca</Anchor> and we'll do our best to get back to you within 24 to 48 hours.
            </Text>
        </div>
    )
}
export default ContactUs;