import { Button, Space, Text } from "@mantine/core";

function AboutTheWalk(props) {

    const wpwOrange = "#f37121"
    const aboutTheWalkLink = "https://www.worldpartnershipwalk.com/about-the-walk/"

    return (
        <div>
            <Space h="sm"></Space>
            <Text align="left">
                The World Partnership Walk, held annually in 10 cities across the country, is Canada's largest public movement to fight global poverty. A volunteer-driven initiative of Aga Khan Foundation Canada, the Walk supports programs that help millions of people in Africa and Asia unlock their potential to build a better life.
            </Text>
            <Space h="xl"></Space>
            <Button style={{backgroundColor: wpwOrange}} onClick={() => {window.open(aboutTheWalkLink)}}>
                Learn More
            </Button>
            <Space h="md"></Space>
        </div>
    )
}
export default AboutTheWalk;