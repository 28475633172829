import { ScrollArea, Badge, Button, Text, Group,TextInput, NumberInput, Space, Title, Checkbox } from "@mantine/core";
import { useEffect, useState } from "react";
import { Mail, Ticket, Phone, IdBadge2 } from "tabler-icons-react";
import { useViewportSize } from '@mantine/hooks';
import SubmittingTicketRequest from "./SubmittingTicketRequest";


function TicketModal(props) {

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [numberOfTickets, setNumberOfTickets] = useState(1)
    const [checkedMinDonation, setCheckedMinDonation] = useState(false);
    const [checkedEmail, setCheckedEmail] = useState(false);
    const [buyTicketEnabled, setBuyTicketEnabled] = useState(true)
    const [ticketRequest, setTicketRequest] = useState({})
    const wpwOrange = buyTicketEnabled ? "#f37121" : "#f3c9b0"
    const buyTicketButtonColor = buyTicketEnabled ? "white" :"darkgrey"

    const [submittingTicketRequest, setSubmittingTicketRequest] = useState(false)
    const { height, width } = useViewportSize();

    useEffect(() => {
        setBuyTicketEnabled(checkedMinDonation && checkedEmail && firstName && lastName && emailAddress && phoneNumber && (numberOfTickets > 0))
        setTicketRequest({
            "Registration Date": (new Date()).toLocaleDateString("en-CA"),
            "First Name": firstName,
            "Last Name": lastName,
            "Email Address": emailAddress,
            "Phone Number": phoneNumber,
            "Ticket Quantity": numberOfTickets,
            "Minimum Donation": (numberOfTickets ? numberOfTickets : 0) * 5
        })
        //setCheckedMinDonation(false)
        //setCheckedEmail(false)
    }, [firstName, lastName, emailAddress, phoneNumber, numberOfTickets, checkedMinDonation, checkedEmail])


    const requestTicket = () => {
        
        setSubmittingTicketRequest(true)
        
    }

    return (
        <ScrollArea style={{ height: Math.min(height - 200, 720), marginRight: '0px' }} type="always" >
            <div style={{paddingRight: '25px', paddingLeft: '10px'}}>
            {submittingTicketRequest ? <SubmittingTicketRequest ga={props.ga} emailAddress={emailAddress} setTicketModalOpened={props.setTicketModalOpened} minimumDonation={(numberOfTickets ? numberOfTickets : 0) * 5} setOpened={setSubmittingTicketRequest} ticketRequest={ticketRequest} opened={submittingTicketRequest}></SubmittingTicketRequest> : null}
            
            <TextInput
                value={firstName}
                onChange={(event) => {
                    setFirstName(event.currentTarget.value)
                    setCheckedMinDonation(false)
                    setCheckedEmail(false)
                    }}
                icon={<IdBadge2 size={14}></IdBadge2>}
                placeholder="Enter your first name"
                label="First name"
                required>    
            </TextInput>
            <Space h="sm"></Space>
            <TextInput
                onChange={(event) => {
                    setLastName(event.currentTarget.value)
                    setCheckedMinDonation(false)
                    setCheckedEmail(false)
                    }}
                value={lastName}
                icon={<IdBadge2 size={14}></IdBadge2>}
                placeholder="Enter your last name"
                label="Last name"
                required>    
            </TextInput>
            <Space h="sm"></Space>
            <TextInput
                onChange={(event) => {
                    setEmailAddress(event.currentTarget.value)
                    setCheckedMinDonation(false)
                    setCheckedEmail(false)
                    }}
                value={emailAddress}
                icon={<Mail size={14}></Mail>}
                placeholder="Enter your email address"
                label="Email address"
                required>    
            </TextInput>
            <Space h="sm"></Space>
            <TextInput
                onChange={(event) => {
                    setPhoneNumber(event.currentTarget.value)
                    setCheckedMinDonation(false)
                    setCheckedEmail(false)
                    }}
                value={phoneNumber}
                icon={<Phone size={14}></Phone>}
                placeholder="Enter your phone number"
                label="Phone number"
                required>    
            </TextInput>
            <Space h="sm"></Space>
            <NumberInput
                icon={<Ticket size={14}></Ticket>}
                label="Ticket Quantity"
                min={1}
                required
                style={{width: "200px"}}
                value={numberOfTickets}
                onChange={(value) => {
                    setNumberOfTickets(value)
                    setCheckedMinDonation(false)
                    setCheckedEmail(false)
                }}
            >
            </NumberInput>
            <Space h="md"></Space>
            <Title order={4}>Minimum donation for {numberOfTickets} ticket{numberOfTickets > 1 ? "s" : ""}:</Title>
            <Space h="sm"></Space>
            <Group direction="column" position="center" style={{rowGap: '2px'}}>
                <Badge color="orange" size="xl" style={{margin: "auto", textAlign: "center"}}>${(numberOfTickets ? numberOfTickets : 0) * 5}.00</Badge>
                <Text size={"xs"}>($5.00 per ticket x {numberOfTickets} ticket{numberOfTickets > 1 ? "s" : ""})</Text>
            </Group>
            <Space h="xl"></Space>
            <Checkbox required={true} checked={checkedMinDonation} onChange={(event) => setCheckedMinDonation(event.currentTarget.checked)} label={"I understand that my tickets and registration are not confirmed until I make a minimum donation of " + "$" + (numberOfTickets ? numberOfTickets * 5 : 0)+ ".00" + " in my donation form in the next step."}></Checkbox>
            <Space h="md"></Space>
            <Checkbox required checked={checkedEmail} onChange={(event) => setCheckedEmail(event.currentTarget.checked)} label="I understand that I must use the same email address as I have provided above in my donation form in the next step."></Checkbox>
            <Space h="md"></Space>
            <Button
                onClick={() => {
                    props.ga.event({
                        category: 'Behaviour',
                        action: 'Button Click',
                        label: 'Walk/BBQ - Register & Buy Tickets (Ticket Modal)'
                    })
                    requestTicket()
                    }}
                fullWidth style={{backgroundColor: wpwOrange, color: buyTicketButtonColor}}
                disabled={!buyTicketEnabled}
            >
                Register & Buy Tickets
            </Button>
            
        </div></ScrollArea>
    )
}
export default TicketModal;