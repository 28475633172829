import { Title, Text, Space } from "@mantine/core";

function FAQ(props) {

    const FAQs = [
        {
            question: "What time does the walk start?",
            answer: "The walk starts at 11:00 AM."
        },
        {
            question: "How much do tickets cost?",
            answer: "There is a minimum $5 donation per person.  Please reserve your ticket by clicking the 'Register & Buy Tickets' button under the 'Tickets & Donations' section above while supplies last."
        },
        {
            question: "When will I get my physical ticket?",
            answer: "You can reserve your tickets by registering and donating online.  On the day of the event, go to the registration desk on site to pick up your physical tickets."
        },
        {
            question: "What is the deadline to purchase my ticket?",
            answer: "Tickets for this event are now sold out."
        },
        {
            question: "What should I bring with me on the day of the walk?",
            answer: "We recommend everyone wear a comfortable pair of walking shoes, bring sunglasses and hats, a reusable water bottle, and an umbrella.  Please also bring your own picnic chairs and/or picnic blankets as seating is very limited."
        },
        {
            question: "What facilities are available at the walk site?",
            answer: "Washrooms, playground, grassy playing areas, walking trails, and a limited number of picnic tables."
        },
        {
            question: "What happens if it rains?",
            answer: "The event will be held rain or shine unless there is severely inclement weather (severe thunderstorms, hail, etc).  Please dress appropriately for the forecasted weather."
        },
        {
            question: "Do I have to wear a mask?",
            answer: "Masking is not required at this outdoor event. We will be following the prevailing guidelines from Ontario Public Health."
        }
    ]

    return (
        <div>
            <Space h="md"></Space>
            {FAQs.map((faq, index) => {
                return (
                    <div key={index} style={{textAlign: "left"}}>
                        <Title order={4}>
                            {faq.question}
                        </Title>
                        <Text>
                            {faq.answer}
                        </Text>
                        <Space h="md"></Space>
                    </div>
                )
            })}
        </div>
    )
}
export default FAQ;