import { Title, Image, Space, Text, Tabs } from "@mantine/core";
import { Directions, Map, Walk } from "tabler-icons-react";
import zoomedOutMap from '../Media/ZoomedOutMap.png'
import walkRoute from '../Media/Walk Route Final.png'
import siteMap from '../Media/SiteMap.png'
import { useViewportSize } from "@mantine/hooks";

function Maps(props) {

    const { height, width } = useViewportSize()

    return (
        <div>
            <Space h="sm"></Space>
            <Tabs>
                <Tabs.Tab label="Address, Parking & Directions" icon={<Directions size={14} />}>
                    <Space h="md"></Space>
                    <Title order={3}>
                        Centennial Park
                    </Title>
                    <Title order={5}>
                        Picnic Area 3
                    </Title>
                    <Space h="md"></Space>
                    <Text>
                    130 Elmcrest Rd
                    </Text>
                    <Text>
                    Etobicoke, ON
                    </Text>
                    <Text>
                    M9C 3S2
                    </Text>
                    <Space h="md"></Space>
                    <Text>
                        There are parking lots at 130, 150, and 151 Elmcrest Rd. with a limited number of parking spots: carpooling is encouraged.
                    </Text>
                    <Space h="xl"></Space>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18245.50220052902!2d-79.59669408818841!3d43.65243451221631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b38466ca44e93%3A0x24bf66571f0b80f5!2s130%20Elmcrest%20Rd%2C%20Etobicoke%2C%20ON%20M9C%203S2!5e0!3m2!1sen!2sca!4v1652934456820!5m2!1sen!2sca" width={Math.min(width - 75, 600)} height="450" style={{border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    
                </Tabs.Tab>
                <Tabs.Tab label="Site Map" icon={<Map size={14} />}>
                    <Space h="md"></Space>
                    <Text>
                        A map of the picnic area, washroom facility, and parking lots is below.
                    </Text>
                    <Space h="xl"></Space>
                    <Image src={siteMap} style={{margin: 'auto', maxWidth: '600px'}} ></Image>
                </Tabs.Tab>
                <Tabs.Tab label="Walk Route" icon={<Walk size={14} />}>
                    <Space h="md"></Space>
                    <Space h="md"></Space>
                    <Text>
                        The finalized walk route has been posted below.  With a distance of approximately 2.9 km, it will take between 30 to 45 minutes to complete, depending on the individual.
                    </Text>
                    <Space h="xl"></Space>
                    <Image src={walkRoute} style={{margin: 'auto', maxWidth: '600px'}} ></Image>
                </Tabs.Tab>
            </Tabs>
            <Space h="md"></Space>
        </div>
    )
}
export default Maps;