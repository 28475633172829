let SendToCloud = {}

SendToCloud.postTicketRequestToGoogleSheets = async function postTicketRequestToGoogleSheets(ticketRequest) {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(ticketRequest);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
        mode: 'no-cors'  
    };

    return fetch("https://script.google.com/macros/s/AKfycbzcDGof8Q6yRunPNbmqynptE8egXc-VMSY3p8UfSlUJgRnb3jBYqeJBNByvw5sBLflJ/exec", requestOptions)
    .then(response => response.text())
    .then(result => {return result})
    .catch(error => {return error});

}

export default SendToCloud