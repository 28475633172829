import {Divider, List, Button, Space, Text } from "@mantine/core";
function Tickets(props) {

    const wpwOrange = "#f37121"
    const donationLink = "https://secure3.convio.net/akfc/site/Donation2?idb=612138846&df_id=1873&FR_ID=1717&mfc_pref=T&PROXY_ID=1227943&PROXY_TYPE=20&1873.donation=form1"
    const donationLinkTeam = "https://secure3.convio.net/akfc/site/TR/2022WPW/General?team_id=21981&pg=team&fr_id=1717"

    return (
        <div>
            <List style={{textAlign: "left"}}>
                <List.Item>minimum $5 donation per person required to obtain a ticket for the event</List.Item>
                <List.Item>register and donate now using the link below to secure your tickets</List.Item>
                    <List style={{textAlign: "left"}}>
                    <List.Item ml={25}><strong>you will receive your physical ticket at the registration desk on the day of the event</strong></List.Item>
                    </List>
                <List.Item>space at the picnic site is extremely limited and <strong>registration for tickets will be stopped once we are sold out</strong></List.Item>
                <List.Item>the last day to register for a ticket will be Friday, June 17 (or while supplies last)</List.Item>
                <List.Item>100% of all ticket sales/donations go directly to support the World Partnership Walk</List.Item>
                <List.Item>your ticket includes a BBQ lunch</List.Item>
            </List>
            <Space h="xl"></Space>
            <Text size="lg" weight={"bold"} color={"red"}>
                TICKETS FOR THIS EVENT ARE NOW SOLD OUT.
            </Text>
            <Text size="lg" weight={"bold"} color={"red"}>
                NO TICKETS WILL BE AVAILABLE ON THE DAY OF THE EVENT.
            </Text>
            <Space h="xl"></Space>
            <Button disabled onClick={() => {
                props.ga.event({
                    category: 'Behaviour',
                    action: 'Button Click',
                    label: 'Walk/BBQ - Buy Tickets (Tickets Accordion)'
                })
                props.openTicketModal(true)
            }}
            >Register for Tickets - SOLD OUT</Button>
            <Space h="xl"></Space>
            <Divider my="sm" variant="dotted" />
            <Text size="sm">If you have already registered for the Centennial Park Walk/BBQ Event but haven't donated the minimum $5 per person to secure your ticket <strong>OR</strong> if you want to donate additional funds to support the Walk/BBQ Event campaign, click the button below.</Text>
            <Space h="sm"></Space>
            <Button style={{backgroundColor: wpwOrange}} onClick={() => {
                props.ga.event({
                    category: 'Behaviour',
                    action: 'Button Click',
                    label: 'Walk/BBQ - Donate Walk/BBQ (Tickets Accordion)'
                })
                window.open(donationLink)
            }}
            >Donate Now - Walk & BBQ Event</Button>
            <Space h="xl"></Space>
            <Text size="sm">If you want to support a specific Etobicoke United participant, click the button below to find the participant and donate to them.  You can also join the team, register as a participant, and complete a self-donation.</Text>
            <Space h="sm"></Space>
            <Button style={{backgroundColor: wpwOrange}} onClick={() => {
                props.ga.event({
                    category: 'Behaviour',
                    action: 'Button Click',
                    label: 'Walk/BBQ - Donate Etobicoke United (Tickets Accordion)'
                })
                window.open(donationLinkTeam)
            }}
            >Donate Now - Etobicoke United</Button>
            <Space h="md"></Space>
        </div>
    )
}
export default Tickets;